<div ngModelGroup="app-device" id="deviceForm" [formGroup]="deviceForm">
    <div class="form-group">
        <ng-container *ngIf="isCreateMode">
            <mat-form-field appearance="outline" floatLabel="always" class="fullwidth" data-automation="parentIdField">
                <mat-label>{{ 'device.thing_id' | translate }}</mat-label>
                <input
                    type="text"
                    formControlName="searchDeviceId"
                    id="searchDeviceId"
                    placeholder="{{ 'device.create.placeholder.thing_id' | translate }}"
                    matInput
                    data-automation="searchDeviceId"
                    (keydown)="hideThingIdFeedback()"
                    [matAutocomplete]="auto"
                />
                <div *ngIf="searchDeviceId.valid">
                    <span
                        data-automation="thing-id-feedback"
                        class="thing-id-feedback log-title log-warning"
                        *ngIf="noDevicesFound"
                    >
                        {{ 'device.notification.thing_id.not_found' | translate }}
                    </span>
                </div>
                <mat-autocomplete
                    #auto="matAutocomplete"
                    data-automation="deviceIdAutocomplete"
                    id="deviceId"
                    (optionSelected)="registerSelection($event.option.value)"
                    [displayWith]="displayFn"
                >
                    <cdk-virtual-scroll-viewport
                        itemSize="48"
                        [style.height.px]="deviceSelectionHeight"
                        minBufferPx="400"
                        maxBufferPx="400"
                    >
                        <mat-option *cdkVirtualFor="let parent of thingRegistrations" [value]="parent">
                            {{ parent.device_id }}
                        </mat-option>
                    </cdk-virtual-scroll-viewport>
                </mat-autocomplete>
                <mat-hint *ngIf="isThingRegistrationLoading" align="end">{{ 'device.loading' | translate }}</mat-hint>
                <div class="suffix-box" matSuffix>
                    <mat-spinner *ngIf="isThingRegistrationLoading" diameter="20"></mat-spinner>
                    <mat-icon
                        svgIcon="info"
                        data-automation="thingIdTooltip"
                        class="tooltip-icon"
                        matTooltip="{{ 'device.create.tooltip.thing_id' | translate }}"
                    >
                    </mat-icon>
                </div>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" data-automation="autoSiteField">
                <mat-label>{{ 'device.site' | translate }}</mat-label>
                <mat-select
                    [required]="selectedThingRegistration != null"
                    [disabled]="!selectedThingRegistration"
                    formControlName="autoSiteId"
                    data-automation="autoSite"
                    [class.loading-field]="loadingSites | async"
                    (selectionChange)="handleAutoSiteSelection()"
                    placeholder="{{
                        (sites | async).length > 0
                            ? ('device.create.placeholder.site' | translate)
                            : ('device.create.placeholder.no_sites' | translate)
                    }}"
                    matTooltip="{{
                        (sites | async).length > 0
                            ? auto_site_label_to_display
                            : ('device.create.placeholder.no_sites' | translate)
                    }}"
                    matTooltipPosition="above"
                >
                    <mat-option
                        *ngFor="let site of sites | async"
                        [value]="site.id"
                        matTooltip="{{ site.displayLabel }}"
                        matTooltipPosition="above"
                    >
                        {{ site.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="siteId.hasError('required')">
                    {{ 'device.validation.required' | translate }}
                </mat-error>
                <mat-spinner *ngIf="loadingSites | async" matSuffix diameter="23"></mat-spinner>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" data-automation="pulseMultiplierField">
                <mat-label>{{ 'device.pulse_multiplier' | translate }}</mat-label>
                <input
                    id="autoPulseMultiplier"
                    matInput
                    type="number"
                    placeholder="{{ 'device.create.placeholder.pulse_multiplier' | translate }}"
                    formControlName="autoPulseMultiplier"
                    data-automation="autoPulseMultiplier"
                    data-url-persist
                    [readonly]="!selectedThingRegistration"
                    [disabled]="!selectedThingRegistration"
                />
            </mat-form-field>
            <div *ngIf="showPrechecks" class="field-log-messages">
                <!--Prechecks-->

                <!--Gateway ID-->
                <span data-automation="gateway-precheck" class="precheck">
                    <mat-icon *ngIf="prechecks.gateway_id == PrecheckStatus.failed" data-automation="fail" class="log-icon fail-icon"
                        >highlight_off</mat-icon
                    >
                    <mat-icon *ngIf="prechecks.gateway_id == PrecheckStatus.success" data-automation="success" class="log-icon success-icon"
                        >check_circle_outlined</mat-icon
                    >
                    {{ 'device.create.precheck.gateway_id' | translate }}
                </span>

                <!--Environment Prefix-->
                <span data-automation="environment-precheck" class="precheck">
                    <mat-icon *ngIf="prechecks.environment_prefix == PrecheckStatus.failed" data-automation="fail" class="log-icon fail-icon"
                        >highlight_off</mat-icon
                    >
                    <mat-icon
                        *ngIf="prechecks.environment_prefix == PrecheckStatus.success"
                        data-automation="success"
                        class="log-icon success-icon"
                        >check_circle_outlined</mat-icon
                    >
                    {{ 'device.create.precheck.environment_prefix' | translate }}
                </span>

                <!--Trend ID-->
                <span data-automation="trend-precheck" class="precheck">
                    <mat-icon *ngIf="prechecks.trend_id == PrecheckStatus.failed" data-automation="fail" class="log-icon fail-icon"
                    >highlight_off</mat-icon
                    >
                    <mat-icon *ngIf="prechecks.trend_id == PrecheckStatus.warn" data-automation="warn" class="log-icon warning-icon"
                    >info_outlined</mat-icon
                    >
                    <mat-icon *ngIf="prechecks.trend_id == PrecheckStatus.success" data-automation="success" class="log-icon success-icon"
                    >check_circle_outlined</mat-icon
                    >
                    {{ 'device.create.precheck.trend_id' | translate }}
                </span>

                <!--Channel ID-->
                <span data-automation="channel-precheck" class="precheck">
                    <mat-icon *ngIf="prechecks.channel == PrecheckStatus.failed" data-automation="fail" class="log-icon fail-icon"
                        >highlight_off</mat-icon
                    >
                    <mat-icon *ngIf="prechecks.channel == PrecheckStatus.warn" data-automation="warn" class="log-icon warning-icon"
                        >info_outlined</mat-icon
                    >
                    <mat-icon *ngIf="prechecks.channel == PrecheckStatus.success" data-automation="success" class="log-icon success-icon"
                        >check_circle_outlined</mat-icon
                    >
                    {{ 'device.create.precheck.channel' | translate }}
                </span>
            </div>
            <div class="failure" *ngIf="failedPrechecks() && showPrechecks">
                <span
                    ><mat-icon class="warn-icon">warning_outlined</mat-icon
                    >{{ 'device.notification.update_configuration' | translate }}</span
                >
            </div>
            <div class="warning" *ngIf="!failedPrechecks() && warnedPrechecks() && showPrechecks">
                <span
                    ><mat-icon class="warn-icon">warning_outlined</mat-icon
                    >{{ 'device.notification.missing_configuration' | translate }}</span
                >
            </div>
            <button
                (click)="autoCreate()"
                id="auto-create-button"
                class="icon-btn x-btn-primary auto-create-button"
                data-automation="autoCreateBtn"
                [disabled]="!canAutoCreate()"
            >
                <mat-icon>done</mat-icon>
                <span class="btn-text">{{ 'device.button.auto_create' | translate }}</span>
            </button>
        </ng-container>
        <div class="section-separator"></div>
        <mat-form-field
            appearance="outline"
            floatLabel="always"
            data-automation="gatewayIdField"
            class="fullwidth"
            id="gatewayId-field"
        >
            <mat-label>{{ 'device.gateway_id' | translate }}</mat-label>
            <input
                id="gatewayId"
                matInput
                placeholder="{{ 'device.create.placeholder.gateway_id' | translate }}"
                formControlName="gatewayId"
                data-automation="gatewayId"
                (focusout)="refreshGetwayActivation(false)"
                (keydown)="hideFeedback()"
                data-url-persist
            />
            <button
                matSuffix
                mat-icon-button
                aria-label="Clear"
                data-automation="gatewayRefresh"
                class="icon-button"
                ngClass="{{ isGatewayLoading && 'no-pointer' }}"
                (click)="refreshGetwayActivation(true)"
                [disabled]="gatewayId.value === null || gatewayId.value === ''"
            >
                <mat-icon *ngIf="!isGatewayLoading">refresh</mat-icon>
                <mat-spinner *ngIf="isGatewayLoading" diameter="20"></mat-spinner>
            </button>

            <div
                *ngIf="show && gatewayId.value"
                class="field-log-messages"
                ngClass="{{ gatewayId.invalid && !gatewayId.hasError('loading') && 'not-found-error' }}"
            >
                <!--Error logs-->
                <span data-automation="log-title" class="log log-title log-error" *ngIf="gatewayId.hasError('inUse')">
                    {{ 'device.notification.gateway.in_use' | translate }}
                    <mat-icon class="log-icon">warning</mat-icon></span
                >

                <span data-automation="log-title" class="log log-title log-error" *ngIf="gatewayId.hasError('pattern')">
                        {{ 'device.notification.gateway.invalid' | translate }}
                    <mat-icon class="log-icon">highlight_off</mat-icon></span
                >
                <!--Success and warning logs-->
                <div *ngIf="gatewayId.valid">
                    <span data-automation="log-title" class="log log-title log-warning" *ngIf="!gatewayExists">
                        {{ 'device.notification.gateway.not_found' | translate }}
                        <mat-icon class="log-icon">warning</mat-icon></span
                    >
                    <span data-automation="log-title" class="log log-title found" *ngIf="isValidGateway">
                        {{ 'device.notification.gateway.success' | translate }}
                        <mat-icon class="log-icon">check_circle</mat-icon></span
                    >
                </div>
            </div>
        </mat-form-field>

        <mat-form-field
            appearance="outline"
            class="read-only"
            floatLabel="always"
            data-automation="signalStrengthField"
        >
            <mat-label>{{ 'device.signal_strength' | translate }}</mat-label>
            <mat-icon
                svgIcon="info"
                data-automation="signalStrengthTooltip"
                matSuffix
                class="tooltip-icon"
                matTooltip="{{ 'device.create.tooltip.signal_strength' | translate }}"
            >
            </mat-icon>
            <input
                id="signalStrength"
                matInput
                readonly
                placeholder="{{ '-' | translate }}"
                formControlName="signalStrength"
                data-automation="signalStrength"
                data-url-persist
            />
            <mat-hint *ngIf="signalStrength.value && signalStrength.value <= -90">
                {{ 'device.validation.signal_quality.range' | translate }}
            </mat-hint>
        </mat-form-field>

        <mat-form-field appearance="outline" class="read-only" floatLabel="always" data-automation="signalQualityField">
            <mat-label>{{ 'device.signal_quality' | translate }}</mat-label>
            <input
                id="signalQuality"
                matInput
                readonly
                placeholder="{{ '-' | translate }}"
                formControlName="signalQuality"
                data-automation="signalQuality"
                data-url-persist
            />
        </mat-form-field>

        <div class="section-separator"></div>
        <form #multiLocaleLabels="ngForm" class="mat-form-field">
            <div class="form-entry" data-automation="displayLabel">
                <multi-locale-input
                    name="displayLabels"
                    [inputLabel]="displayLabelName"
                    [(ngModel)]="multidisplayLabels"
                    [locales]="supportedLocales.value"
                    [displayLabelKey]="multiLocaleConfig.displayLabelKey"
                    [localeKey]="multiLocaleConfig.localeKey"
                    [defaultLocale]="getDefaultLocale()"
                    [placeholderText]="displayLabelPlaceholder"
                    [required]="true"
                    id="displayLabels"
                    #multilabels
                >
                </multi-locale-input>
            </div>
        </form>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="supportedLocalesField">
            <mat-label>{{ 'device.supported_locales' | translate }}</mat-label>
            <mat-select
                formControlName="supportedLocales"
                data-automation="supportedLocales"
                placeholder="{{ 'device.create.placeholder.supported_locales' | translate }}"
                (selectionChange)="handleSupportedLocalesSelection()"
                multiple
            >
                <mat-option
                    *ngFor="let locale of locales"
                    [value]="locale"
                    [disabled]="limitLocaleSelectList(5, locale) || onlyLocaleSelected(locale)"
                >
                    {{ locale.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="defaultLocaleField">
            <mat-label>{{ 'device.default_locale' | translate }}</mat-label>
            <mat-select
                formControlName="defaultLocale"
                data-automation="defaultLocale"
                placeholder="{{ 'device.create.placeholder.default_locale' | translate }}"
            >
                <mat-option
                    *ngFor="let supportedLocale of deviceForm.controls.supportedLocales.value"
                    [value]="supportedLocale.localeName"
                >
                    {{ supportedLocale.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="statusField">
            <mat-label>{{ 'device.status' | translate }}</mat-label>
            <mat-select
                required
                formControlName="status"
                data-automation="status"
                placeholder="{{ 'device.create.placeholder.status_selector' | translate }}"
            >
                <mat-option
                    *ngFor="let status of statuses"
                    [value]="status.name"
                    [disabled]="status.name === 'ORPHANED'"
                >
                    {{ status.displayLabel | translate }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="status.hasError('required')">
                {{ 'device.validation.required' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="siteField">
            <mat-label>{{ 'device.site' | translate }}</mat-label>
            <mat-select
                required
                formControlName="siteId"
                data-automation="site"
                [class.loading-field]="loadingSites | async"
                (selectionChange)="handleSiteSelection()"
                placeholder="{{
                    (sites | async).length > 0
                        ? ('device.create.placeholder.site' | translate)
                        : ('device.create.placeholder.no_sites' | translate)
                }}"
                matTooltip="{{
                    (sites | async).length > 0
                        ? site_label_to_display
                        : ('device.create.placeholder.no_sites' | translate)
                }}"
                matTooltipPosition="above"
            >
                <mat-option
                    *ngFor="let site of sites | async"
                    [value]="site.id"
                    matTooltip="{{ site.displayLabel }}"
                    matTooltipPosition="above"
                >
                    {{ site.displayLabel }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="siteId.hasError('required')">
                {{ 'device.validation.required' | translate }}
            </mat-error>
            <mat-spinner *ngIf="loadingSites | async" matSuffix diameter="23"></mat-spinner>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="managedEquipmentField">
            <mat-label>{{ 'device.managed_equipment' | translate }}</mat-label>
            <mat-select
                required
                formControlName="equipmentIds"
                data-automation="managedEquipment"
                [class.loading-field]="loadingEquipment | async"
                placeholder="{{ getEquipmentsPlaceholder(equipments | async) | translate }}"
                multiple
            >
                <mat-option
                    *ngFor="let equipment of equipments | async"
                    [value]="equipment.id"
                    [disabled]="equipment.siteServerId && !deviceEquipmentIds.includes(equipment.id)"
                >
                    {{ equipment.displayLabel }}
                </mat-option>
            </mat-select>
            <mat-error data-automation="managedEquipmentRequiredError" *ngIf="equipmentIds.hasError('required')">
                {{ 'device.validation.required' | translate }}
            </mat-error>
            <mat-spinner *ngIf="loadingEquipment | async" matSuffix diameter="23"></mat-spinner>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="macAddressField">
            <mat-label>{{ 'device.mac_address' | translate }}</mat-label>
            <input
                id="macAddress"
                matInput
                placeholder="{{ 'device.create.placeholder.mac_address' | translate }}"
                formControlName="macAddress"
                data-automation="macAddress"
                data-url-persist
            />
            <mat-error *ngIf="macAddress.hasError('pattern')">
                {{ 'device.validation.invalid.mac_address' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="hostField">
            <mat-label>{{ 'device.host' | translate }}</mat-label>
            <input
                id="host"
                matInput
                placeholder="{{ 'device.create.placeholder.host' | translate }}"
                formControlName="host"
                data-automation="host"
                data-url-persist
            />
            <mat-error *ngIf="host.hasError('maxlength')">
                {{ 'device.validation.host.max_length' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="portField">
            <mat-label>{{ 'device.port' | translate }}</mat-label>
            <input
                id="port"
                matInput
                step="1"
                type="number"
                placeholder="{{ 'device.create.placeholder.port' | translate }}"
                formControlName="port"
                data-automation="port"
                data-url-persist
            />
            <mat-error *ngIf="port.hasError('min') || port.hasError('pattern')">
                {{ 'device.validation.invalid.port' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="gatewayTypeField">
            <mat-label>{{ 'device.gateway_type' | translate }}</mat-label>
            <mat-select
                formControlName="gatewayType"
                data-automation="gatewayType"
                placeholder="{{ 'device.create.placeholder.gateway_type' | translate }}"
            >
                <mat-option [value]="null">{{ 'device.create.placeholder.gateway_type' | translate }}</mat-option>
                <mat-option *ngFor="let gatewayType of gatewayTypes | async" [value]="gatewayType.code">
                    {{ gatewayType.displayLabel }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="crmIdField">
            <mat-label>{{ 'device.crm_id' | translate }}</mat-label>
            <input
                id="crmId"
                matInput
                placeholder="{{ 'device.create.placeholder.crm_id' | translate }}"
                formControlName="crmId"
                data-automation="crmId"
                data-url-persist
            />
            <mat-error *ngIf="crmId.hasError('pattern')">
                {{ 'device.validation.invalid.crm_id' | translate }}
            </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" floatLabel="always" data-automation="installerContactIdField">
            <mat-label>{{ 'device.installer_contact_id' | translate }}</mat-label>
            <input
                id="installerContactId"
                matInput
                placeholder="{{ 'device.create.placeholder.installer_contact_id' | translate }}"
                formControlName="installerContactId"
                data-automation="installerContactId"
                data-url-persist
            />
            <mat-error *ngIf="installerContactId.hasError('pattern')">
                {{ 'device.validation.invalid.uuid' | translate }}
            </mat-error>
        </mat-form-field>

        <div class="form-entry-column">
            <div class="checkbox-wrapper">
                <mat-checkbox
                    id="includeInCalcs"
                    class="input-entry"
                    name="includeInCalcs"
                    formControlName="includeInCalcs"
                    data-automation="includeInCalcs"
                    data-url-persist
                >
                    {{ 'device.include_in_calcs' | translate }}
                </mat-checkbox>
            </div>
            <div class="checkbox-wrapper">
                <mat-checkbox
                    id="emulated"
                    class="input-entry"
                    name="emulated"
                    formControlName="emulated"
                    data-automation="emulated"
                    data-url-persist
                    (change)="handleEmulatedChange()"
                >
                    {{ 'device.emulated' | translate }}
                </mat-checkbox>
            </div>
        </div>

        <mat-form-field appearance="outline" floatLabel="always" class="multi-line" data-automation="notesField">
            <mat-label>{{ 'device.notes' | translate }}</mat-label>
            <textarea
                matInput
                id="notes"
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                formControlName="notes"
                placeholder="{{ 'device.create.placeholder.notes' | translate }}"
                data-automation="notes"
                data-url-persist
                cdkAutosizeMinRows="18"
            ></textarea>
            <mat-error *ngIf="notes.hasError('maxlength')">
                {{ 'device.validation.notes.max_length' | translate }}
            </mat-error>
        </mat-form-field>

        <div *ngIf="emulated.value" data-automation="emulatedConfigurations">
            <div class="header-title">
                <h2 data-automation="emulatedConfigurationsTitle">
                    {{ 'device.emulated_configurations.title' | translate }}
                </h2>
            </div>
            <h3 class="header-subtitle">{{ 'device.emulated_configurations.interval_data_types' | translate }}</h3>
            <mat-form-field appearance="outline" floatLabel="always" data-automation="channelsField">
                <mat-label>{{ 'device.channels' | translate }}</mat-label>
                <mat-select
                    #intervalChannels
                    required
                    data-automation="channels"
                    formControlName="channels"
                    placeholder="{{ 'device.create.placeholder.channels' | translate }}"
                    multiple
                    [ngModelOptions]="{ standalone: true }"
                    (selectionChange)="handleChannelSelection()"
                >
                    <mat-option *ngFor="let channel of channels | async" [value]="channel.id">
                        {{ channel.displayLabel }}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="intervalChannels.errorState">
                    {{ 'device.validation.required' | translate }}
                </mat-error>
            </mat-form-field>

            <div class="spacer"></div>

            <div
                class="channel-row"
                formGroupName="intervalDataTypes"
                *ngFor="let channelId of selectedChannels; let index"
            >
                <div [formGroupName]="channelId" class="interval-data-types">
                    <div class="channel-name">
                        <mat-label>{{ getChannelDisplayLabel(channelId) | async }}</mat-label>
                    </div>
                    <div class="interval-value type-selector">
                        <mat-form-field
                            appearance="outline"
                            floatLabel="always"
                            [attr.data-automation]="'mappedType' + channelId + 'Field'"
                        >
                            <mat-label>{{ 'device.mapped_type' | translate }}</mat-label>
                            <mat-select
                                required
                                [attr.data-automation]="'mappedType' + channelId"
                                formControlName="type"
                                placeholder="{{ 'device.create.placeholder.mapped_type' | translate }}"
                                [ngModelOptions]="{ standalone: true }"
                                (selectionChange)="handleMappedTypeChange(channelId)"
                            >
                                <mat-option
                                    *ngFor="let mappedType of mappedTypes"
                                    defaultTabIndex="0"
                                    [value]="mappedType.type"
                                >
                                    {{ mappedType.displayLabel | translate }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="interval-value">
                        <mat-form-field
                            appearance="outline"
                            floatLabel="always"
                            [attr.data-automation]="'intervalDataTypesMin' + channelId + 'Field'"
                        >
                            <mat-label *ngIf="getIntervalType(channelId) == 'FIXED'">{{
                                'device.interval_data_types.fixed' | translate
                            }}</mat-label>
                            <mat-label *ngIf="getIntervalType(channelId) == 'RANDOM'">{{
                                'device.interval_data_types.random.min' | translate
                            }}</mat-label>
                            <input
                                id="intervalDataTypesMin{{ channelId }}"
                                formControlName="N"
                                matInput
                                required
                                type="number"
                                [placeholder]="
                                    (getIntervalType(channelId) == 'FIXED'
                                        ? 'device.create.placeholder.interval_fixed'
                                        : 'device.create.placeholder.interval_random_min'
                                    ) | translate
                                "
                                [attr.data-automation]="'intervalDataTypesMin' + channelId"
                                data-url-persist
                                (keyup)="updateFieldValidations(channelId)"
                                (change)="updateFieldValidations(channelId)"
                            />
                            <mat-error *ngIf="getMinControl(channelId).hasError('required')">
                                {{ 'device.validation.required' | translate }}
                            </mat-error>
                            <mat-error *ngIf="getMaxMinValidity(channelId)">
                                {{ 'device.validation.min_less_than_max' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="interval-value" *ngIf="getIntervalType(channelId) == 'RANDOM'">
                        <mat-form-field
                            appearance="outline"
                            floatLabel="always"
                            [attr.data-automation]="'intervalDataTypesMax' + channelId + 'Field'"
                        >
                            <mat-label>{{ 'device.interval_data_types.random.max' | translate }}</mat-label>
                            <input
                                id="intervalDataTypesMax{{ channelId }}"
                                formControlName="M"
                                matInput
                                required
                                type="number"
                                placeholder="{{ 'device.create.placeholder.interval_random_max' | translate }}"
                                [attr.data-automation]="'intervalDataTypesMax' + channelId"
                                data-url-persist
                                (keyup)="updateFieldValidations(channelId)"
                                (change)="updateFieldValidations(channelId)"
                            />
                            <mat-error *ngIf="getMaxControl(channelId)?.hasError('required')">
                                {{ 'device.validation.required' | translate }}
                            </mat-error>
                            <mat-error *ngIf="getMaxMinValidity(channelId)">
                                {{ 'device.validation.max_bigger_than_min' | translate }}
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
